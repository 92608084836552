* {
  margin: 0px;
  padding: 0px;
}
.room {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  border: none;
}
