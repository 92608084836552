.nav {
  height: 100px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.5%;
  padding-right: 2.5%;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
  position: relative;
}

.pages {
  width: 60%;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
}

.pageTitle {
  /* color: #4985a4; */
  color: #797979;
  display: block;
  margin-right: 3em;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  text-align: justify;
  white-space: nowrap;
  /* height: 100%;
  background-color: red; */
}

.pageTitle:hover {
  cursor: pointer;
}

.signInBtn {
  background-color: #638a2f;
  min-width: 100px;
  max-width: 100px;
  margin-left: 20px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  /* margin-right: 30px; */
  border: none;
}
