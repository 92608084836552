.linkBox {
  background-color: #f1f1f1;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  height: 40px;
  min-width: 500px;
  display: inline-flex;

  align-items: center;
}

.linkText {
  font-weight: normal;
  padding-left: 10px;
  font-size: 15px;
  min-width: 450px;
}

.copyButton {
  width: 40px;
  height: 41px;
  background-color: #638a2f;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copied {
  width: 40px;
  height: 41px;
  background-color: #92bb5a;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyIcon {
  margin-top: 6px;
  width: 25px;
}
