.formContainer {
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 700px;
  padding: 50px;
  justify-content: space-between;
  align-items: space-between;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  /* min-width: 1000px; */
  width: 100%;
  justify-content: space-between;
  background-color: "blue";
}

.field {
  display: flex;
  flex-direction: column;
}

.inputBox {
  height: 40px;
  width: 300px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}

.appBtnRow {
  width: 100%;
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  align-items: flex-end;
  /* background-color: red; */
  padding: auto;
  text-align: "right";
}
