.welcomeText {
  height: 50px;
  color: #638a2f;
  font-size: 30px;
  margin-bottom: 15px;
}

.pageContainer {
  height: 100vh;
  width: 100%;
  background-color: #294984;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  max-height: 700px;
  min-width: 450px;
  max-width: 500px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
}

.loginButton {
  position: absolute;

  height: 35px;
  width: 100px;
  font-size: 15px;
  background-color: #638a2f;
  color: white;
  border-width: 0px;
  border-radius: 8px;
  display: block;
  font-weight: bold;
}

.btnRow {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
}
