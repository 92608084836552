.msgContainer {
  height: 75%;
  width: 100%;
  padding-top: 3%;
  padding-right: 10%;
  padding-left: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* min-height: 90vh; */
  /* background-color: #61dafb; */
}

.infoCol {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  max-height: 100%;
  /* padding-top: 10%; */
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: green; */
}

.infoRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5%;
  /* background-color: blue; */
}

.patientName {
  font-size: 20px;
  font-weight: bold;
  color: #638a2f;
}

.infoLabel {
  color: #c5c5c5;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-weight: normal;
  /* background-color: darkviolet; */
}

.formCol {
  display: flex;
  height: 100%;
  flex-direction: row;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  /* background-color: darkred; */
}

.bigInput {
  height: 130px;
  width: 500px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}

.sndFormRow {
  margin-top: 30px;
}

.sendBtnRow {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* background-color: darkgoldenrod; */
}

.sendBtn {
  background-color: #638a2f;
  border: 0px solid #638a2f;
  width: 200px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
}

button:hover {
  background-color: #7da14c;
}
