.master {
  width: 100vw;
  height: 100%;
  overflow-x: hidden !important;
  /* background-color: green; */
  /* min-height: 95vh; */
  /* max-width: 100vw; */
}
.contentContainer {
  height: 100%;
  /* min-height: 95vh; */
  width: 100%;
  /* background-color: #61dafb; */
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.header {
  background-color: cadetblue;
  display: flex;
  height: 75px;
  width: 100%;
  /* margin-bottom: 30px; */
}
.clientLogo {
  height: 70px;
}
.afyaLogo {
  height: 40px;
}

.loginBtn {
  background-color: #638a2f;
  border: 0px solid #638a2f;
  max-width: 100px;
  max-height: 30px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
}

.footer {
  color: #2e64ae;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 100vh;
  margin-bottom: 20px;
  margin-bottom: 20px;
  width: 100%;
  /* overflow-x: hidden; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.shortInputBox {
  height: 40px;
  width: 250px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}

.inputBox {
  height: 40px;
  width: 300px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}

.bigInput {
  height: 130px;
  width: 500px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}

.form {
  width: 100%;
  height: 100%;
}

.submitBtn {
  background-color: #638a2f;
  border: 0px solid #638a2f;
  width: 100px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendBtn {
  background-color: #638a2f;
  border: 0px solid #638a2f;
  width: 200px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
}

button:hover {
  background-color: #7da14c;
}

.msgRow {
  display: flex;
  flex-direction: row;
  width: 1200px;
  align-items: space-between;
  justify-content: space-between;
  /* background-color: cadetblue; */
  margin-bottom: 30px;
}

.sendBtnRow {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* background-color: darkgoldenrod; */
}

.msgContainer {
  height: 75%;
  width: 100%;
  padding-top: 3%;
  padding-right: 10%;
  padding-left: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* min-height: 90vh; */
  /* background-color: #61dafb; */
}

.infoCol {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  max-height: 100%;
  /* padding-top: 10%; */
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: green; */
}

.infoRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5%;
  /* background-color: blue; */
}

.formCol {
  display: flex;
  height: 100%;
  flex-direction: row;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  /* background-color: darkred; */
}

.sndFormRow {
  margin-top: 30px;
}

.infoSeg {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-right: 50px;
  font-size: 20px;
  /* font-weight: bold; */
}

.patientName {
  font-size: 20px;
  font-weight: bold;
  color: #638a2f;
}

.infoLabel {
  color: #c5c5c5;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-weight: normal;
  /* background-color: darkviolet; */
}
