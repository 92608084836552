.error {
  color: #c70808;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 2px;
  /* justify-content: center; */
  /* background-color: darkcyan; */
  font-size: 11px;
  height: 20px;
}
