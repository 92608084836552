.inputBox {
  height: 40px;
  width: 300px;
  border: 2px solid #2e64ae;
  border-radius: 4px;
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10px;
}

.field {
  display: flex;
  flex-direction: column;
}

.label {
  color: #2e64ae;
  font-size: 15px;
  font-weight: bold;
}
