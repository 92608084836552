.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4%;
  box-sizing: border-box;
  min-height: 95vh;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.filters {
  display: flex;
  width: 900px;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: red; */
}
.filterLabel {
  margin-bottom: 3px;
  color: #9d9d9d;
  font-size: 15px;
  font-weight: 450;
}

.cardRow {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 20px;
  /* background-color: #638a2f; */
}

.card {
  width: 900px;
  min-height: 120px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.cardBody {
  display: flex;
  align-items: center;
  width: 900px;
  min-height: 100px;
}

.timeSection {
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 15%;
}

.line {
  border-left: 3px solid #c4c4c4;
  height: 80px;
  margin-right: 5px;
}

.status {
  width: 100px;
  height: 30px;
  border-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 2px;
  padding-right: 10px; */
}

.statusSection {
  width: 15%;
  /* background-color: aquamarine; */
  display: flex;
  justify-content: center;
}

.noun {
  /* margin-bottom: 5px; */
  margin-left: 5px;
}

.names {
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  width: 14%;
  color: #5b5b5b;
  justify-content: center;
  align-items: center;
  /* background-color: burlywood; */
}

.dateSection {
  width: 15%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  /* background-color: blueviolet; */
}

.actionSection {
  /* background-color: cornflowerblue; */
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.joinBtn {
  background-color: #638a2f;
  min-width: 100px;
  max-width: 100px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  margin-right: 20px;
  border: 2px solid #638a2f;
}

.copyBtn {
  background-color: white;
  min-width: 100px;
  max-width: 100px;
  height: 40px;
  border: 2px solid #638a2f;
  border-radius: 10px;
  color: #638a2f;
  font-weight: bold;
  font-size: 18px;
}

.copyBtn:hover {
  background-color: rgb(219, 219, 219);
  min-width: 100px;
  max-width: 100px;
  height: 40px;
  border: 3px solid #638a2f;
  border-radius: 10px;
  color: #638a2f;
  font-weight: bold;
  font-size: 18px;
}

.tooltip-container {
  position: relative;
}

.tooltip-box {
  position: absolute;
  background-color: white;
  color: black;
  padding: 5px;
  border: 2px solid #2e64ae;
  min-width: 170px;
  border-radius: 5px;
  bottom: calc(100% + 5px);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: none;
}

.tooltip-box.visible {
  display: block;
}

.statusLine {
  display: flex;
  flex-direction: row;
  /* background-color: cyan; */
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: center;
  justify-self: center;
}

.statusLabel {
  margin-left: 5px;
}
