* {
  margin: 0px;
  padding: 0px;
}
.roomContainer {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}
